import { useCreateObject, useUpdateObject, useDeleteObject } from './utils';

export const useUpdateContact = (buildingId) => {
    const { mutateAsync } = useUpdateObject(['schedule', buildingId], '/contact');
    return mutateAsync;
};

export const useCreateContact = (buildingId) => {
    const { mutateAsync } = useCreateObject(['contact', buildingId], '/contact');
    return mutateAsync;
};

export const useDeleteContact = (buildingId) => {
    const { mutateAsync }= useDeleteObject(['contact', buildingId], '/contact');
    return mutateAsync;
};

